.terms-condition-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;

  .terms-content {
    max-width: 800px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: justify;
    line-height: 1.6;
    font-size: 16px;
    font-family: "Arial", sans-serif;
    color: #333;
    overflow: scroll;

    .terms-header {
      text-align: center;
      margin-bottom: 20px;
      font-size: 24px;
      color: #204f84;
    }
  }
  .terms-footer {
    position: relative;
    left: 20%;
    margin-top: 10px;
    display: flex;
    gap: 10px;

    .agree-button {
      background-color: #204f84 !important;
    }

    .decline-button {
      color: #204f84;
    }
  }
}
