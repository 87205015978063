.success-response {
  .success-response-img {
    padding: 50% 0 5% 28%;
  }
  .response-msg {
    width: 18rem;
    font-size: 13px;
    padding-left: 8px;
  }
}
