.reports-list-container-text {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  .reports-list-container-filter {
    display: flex;
  }
}
.reports-list-container-filter-options {
  display: flex;
  flex-direction: column;
  > span:first-child {
    margin-bottom: 5px;
  }
}
