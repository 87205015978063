.email-card {
  .otp-validation-container {
    padding: 20px 0 0 0;

    .inner-otp-validation-container {
      padding: 0 0 0 6%;

      .otp-validator-header {
        h3 {
          margin-bottom: 0px !important;
        }
        .col-gap-5 {
          margin-top: 6px;
        }
        padding: 0 0 0 10%;
        .email-id-container {
          font-size: 12px;
          line-break: anywhere;
          width: 80%;
        }
        .edit-icon {
          border-style: hidden;
          height: 15px;
          width: 17px;
        }
        .col-gap-5 {
          column-gap: 5px;
        }
      }
      .otp-validation-form-container {
        padding: 0 20px 0 0;
        .otp-validation-form {
          .ant-form-item {
            margin-bottom: 0px !important;
          }
          .otp-validation-form-inner {
            gap: 8px;
            padding: 40px 0 0 0;
            .otp-input-field {
              width: 48px;
              height: 54px;
              font-size: 18px;
              text-align: center;
            }
            .border-red {
              border-color: red;
            }
            .justify-items-end {
              justify-items: "flex-end";
            }
            .gap-10 {
              gap: 10px;
            }
            .gap-5 {
              gap: 5px;
            }
          }
          .otp-resend-button {
            border: none;
            background-color: #fff;
            font-size: 13px;
          }
          .btn-disabled-color {
            color: #8e8e8e;
          }
          .hide-timer {
            display: none;
          }
          .form-submit-btn {
            width: 307px;
            height: 36px;
          }
        }
      }
    }
  }
}
