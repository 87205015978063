.additional-details-container {
  h3 {
    margin: 10px 0 10px 0 !important;
    line-height: 46px !important;
  }
  .free-trail-additional-details-form {
    .ant-form-item {
      margin-bottom: 0px !important;
      .ant-col-24.ant-form-item-label {
        padding: 0 0 0 !important;
      }
    }
    .select-industry {
      .ant-select-selector {
        border: 1px solid #aeaeae !important;
        border-radius: 4px !important;
      }
    }
    .form-input {
      width: 100%;
      height: 34px;
    }
    .terms-btn-container {
      gap: 3px;
      padding: 15px 0 5px 0;
      .mt-6 {
        margin-top: 6px;
      }
      .terms-btn {
        padding: 0px 4px;
      }
      .hover-btn-animate {
        &:hover ~ .terms-btn {
          animation: wobble 1s ease;
        }
      }
    }
    .terms-radio-btn {
      cursor: not-allowed;
    }
  }
}

@keyframes wobble {
  // 0%,
  // 100% {
  //   transform: translateX(0);
  // }
  // 15% {
  //   transform: translateX(-25%);
  // }
  30% {
    transform: translateX(10%);
  }
  45% {
    transform: translateX(-10%);
  }
  50% {
    transform: translateX(5%);
  }
  55% {
    transform: translateX(-5%);
  }
  // 60% {
  //   transform: translateX(10%);
  // }
  // 75% {
  //   transform: translateX(-5%);
  // }
}
