.email-input-form {
  width: 294px;

  > div:first-child {
    margin-top: 120px;
  }
  > div:nth-child(2) {
    margin-top: 100px;
  }
}
.default-button-class {
  font-weight: 600;
  color: #00244b;
  border: 1px solid #00244b;
  border-radius: 4px;
}
@media screen and (max-height: 600px) {
  .email-input-form {
    width: 294px;

    > div:first-child {
      margin-top: 60px;
    }
    > div:nth-child(2) {
      margin-top: 40px;
    }
  }
}
