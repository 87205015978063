.terms-modal-content {
  width: 345px !important;
  height: 482px !important;
  .ae-modal-headind-icon-container {
    justify-content: center;
  }
  .modal-footer {
    justify-content: center;
  }
  .scrollable-container {
    width: 100%;
    max-width: 600px;
    height: 385px;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: #f9f9f9;

    .item {
      padding: 10px;
      margin-bottom: 5px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 11px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .btn-agree {
      border: 1px solid;
      height: 2rem;
    }
  }
}
