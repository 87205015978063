.sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  .sign-up-container-form {
    height: calc(100% - 100px);
    .sign-up-container-form-inner {
      overflow-y: scroll;
      height: 100%;
    }
  }
  h3 {
    margin: 10px 0 10px 0 !important;
  }
  &.success {
    justify-content: center;

    width: 80%;
    text-align: center;
    margin: auto;
  }
}
