.report-card {
  height: 360px;
  max-height: 360px;
  width: 300px;
  border-width: 1.5px 1.5px 1.5px 1.5px;
  padding: 12px;

  border-radius: 4px;
  // border-style: solid;

  // border-color: #ccdef2;
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  box-shadow: 0.5px 0.5px 1px 1px rgba(0, 0, 24, 0.1);

  // padding: 16px;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  &:hover {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 24, 0.5);
    border-width: 0px 0px 0px 0px;
    transform: scale(1.0014);
  }
  .report-card-img {
    height: 120px;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    // box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 24, 0.5);

    border: 0.1px solid rgba(0, 0, 24, 0.1);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .report-card-name {
    width: 100%;

    font-weight: 700;
    font-size: 16px;
    color: black;
    height: 30px;
  }
  .report-card-prod {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 0.1px solid rgba(0, 0, 24, 0.1);
  }
  .report-card-desc {
    width: 100%;
    border-bottom: 0.1px solid rgba(0, 0, 24, 0.1);
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    height: 100px;

    p:nth-child(2) {
      font-weight: 470;
      text-align: start;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      height: 100%;
      padding-bottom: 2px;
      white-space: normal;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    p:first-child {
      font-size: 11px;
      color: #8e8e8e;
    }
  }
  .report-card-created-on {
    display: flex;
    font-size: 11px;
    height: 30px;
    color: #8e8e8e;
    padding: 5px 0 5px 0;
    > div {
      margin-right: 10px;
    }
  }
}
