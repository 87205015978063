.forgot-password-container {
  height: 100%;
  width: 294px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  > span {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  > form {
    margin-bottom: 20px;
  }
}
