.realm-list-container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;

  .realms-list-inner-container {
    height: 100%;
    width: 100%;
    padding: 23px 45px 30px 84px;

    .realm-list-container-header {
      height: 68px;
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .realm-list-container-text {
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }
    .realm-list-container-tenant-list-container {
      width: 100%;
      height: calc(100% - 222px);
      display: flex;
      margin-top: 100px;
      .realm-card {
        width: 290px;
        height: 82px;
        background-color: #f2f2f2;
        border-bottom: 8px solid #ccdef2;
        margin-right: 2rem;
        display: flex;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          > p {
            color: #005abc;
            font-size: 16px;
            font-weight: 700;
          }
        }
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
        &:hover {
          box-shadow: 0.3px 0.3px 2px 1px rgba(0, 0, 24, 0.5);
          transform: scale(1.0014);
        }
      }
    }
  }
}
