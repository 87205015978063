.landing-page {
  background: #e5e5e5;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .email-card {
    // background-color: white;
    width: 400px;
    height: 550px;
    // position: absolute;
    // right: 6%;
    // bottom: 22.5%;
    background: #ffffff;
    // margin: 0px auto;
    // padding: 50px 70px 70px 90px !important;
    // box-shadow: 0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13),
    // 	0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12);
    border-width: 1px 1px 13px 1px;
    border-style: solid;
    border-color: #216ca0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .email-input-header {
      margin-top: 50px;
      height: 70px;
      > img {
        height: 55px;
      }
    }
    .login-footer {
      position: absolute;
      left: 0px;
      bottom: 10px;
      width: 100%;
      color: black;
      text-align: center;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
      > p {
        > a {
          color: #005abc;
        }
      }
    }
  }
  .support-label-container {
    font: "Open Sans";
    font-size: 13px;
    margin-top: 8px;
    span {
      color: blue;
    }
  }
}
@media screen and (max-height: 600px) {
  .landing-page {
    .email-card {
      height: 500px;
      width: 357px;
      overflow-y: scroll;
    }
    .email-input-form {
      height: 250px;
    }
  }
}
// @media (max-width: 1366px) {
// 	// #kc-form-login > div > label {
// 	//     font-size: 12px;
// 	//     margin-bottom: 3px;
// 	// }
// 	.landing-page {
// 		.dde-header {
// 			font-size: 16px;
// 			font-weight: 700;
// 			text-align: left;
// 			margin-left: 4.8em;
// 			line-height: 19px;
// 			height: auto;
// 			width: auto;
// 		}
// 		.dde-textbox {
// 			/* width: 384px;
//             height: 300px;
//             display: flex;
//             flex-direction: column;
//             align-content: space-between;
//             justify-items: center; */
// 			color: #ffffff;
// 			width: 384px;
// 			height: 300px;
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: space-between;
// 			color: #ffffff;
// 			/* right: -%; */
// 			position: absolute;
// 			right: 50%;
// 			bottom: 39%;
// 		}
// 		.dde-textbox-header {
// 			width: 100%;
// 			font-size: 48px;
// 			line-height: 64px;
// 			text-align: left;
// 		}
// 		.dde-textbox-text {
// 			width: 100%;
// 			font-size: 13px;
// 			line-height: 18px;
// 			text-align: left;
// 		}
// 		h1#kc-page-title {
// 			margin-top: 10px;
// 			font-size: 24px;
// 			line-height: 32px;
// 			color: #204f84 !important;
// 			font-weight: 700;
// 			/* font-style: oblique; */
// 			text-align: left;
// 			margin-bottom: 20px;
// 			width: 200px;
// 			height: 64px;
// 		}
// 		#kc-form-buttons {
// 			margin-top: 20px;
// 		}
// 		.email-card {
// 			margin: 0 auto;
// 			// box-shadow: var(--pf-global--BoxShadow--lg);
// 			padding: 0 20px;
// 			width: 486px;
// 			height: 470px;
// 			padding: 50px 70px 70px 90px !important;
// 			position: absolute;
// 			right: 6%;
// 			bottom: 22.5%;
// 			box-shadow: 0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13),
// 				0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12);
// 			/* display: none; */
// 			/* border-top: 4px solid; */
// 			/* border-color: #0066CC; default - IE compatibility */
// 			/* border-color: var(--pf-global--primary-color--100); */
// 		}
// 	}
// }
// @media (max-width: 1024px) {
// 	.landing-page {
// 		.dde-header {
// 			font-size: 16px;
// 			font-weight: 700;
// 			text-align: left;
// 			margin-left: 4.8em;
// 			line-height: 19px;
// 			height: auto;
// 			width: auto;
// 		}
// 		.dde-textbox {
// 			/* width: 384px;
//             height: 300px;
//             display: flex;
//             flex-direction: column;
//             align-content: space-between;
//             justify-items: center; */
// 			color: #ffffff;
// 			width: 300px;
// 			height: 200px;
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: space-between;
// 			color: #ffffff;
// 			/* right: -%; */
// 			position: absolute;
// 			right: 50%;
// 			bottom: 39%;
// 		}
// 		.dde-textbox-header {
// 			width: 100%;
// 			font-size: 24px !important;
// 			line-height: 32px;
// 			text-align: left;
// 		}
// 		.dde-textbox-text {
// 			width: 100%;
// 			font-size: 11px !important;
// 			line-height: 14px;
// 			text-align: left;
// 		}
// 		h1#kc-page-title {
// 			margin-top: 10px;
// 			font-size: 24px;
// 			line-height: 32px;
// 			color: #204f84 !important;
// 			font-weight: 700;
// 			/* font-style: oblique; */
// 			text-align: left;
// 			margin-bottom: 20px;
// 			width: 200px;
// 			height: 64px;
// 		}
// 		#kc-form-buttons {
// 			margin-top: 20px;
// 		}
// 		.email-card {
// 			margin: 0 auto;
// 			// box-shadow: var(--pf-global--BoxShadow--lg);
// 			padding: 0 20px;
// 			width: 360px;
// 			height: 360px;
// 			padding: 25px 65px 35px 45px !important;
// 			position: absolute;
// 			right: 6%;
// 			bottom: 22.5%;
// 			box-shadow: 0 0.1875rem 0.4375rem 0.1875rem rgba(3, 3, 3, 0.13),
// 				0 0.6875rem 1.5rem 1rem rgba(3, 3, 3, 0.12);
// 			/* display: none; */
// 			/* border-top: 4px solid; */
// 			/* border-color: #0066CC; default - IE compatibility */
// 			/* border-color: var(--pf-global--primary-color--100); */
// 		}
// 	}
// }
