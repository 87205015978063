.product-card {
  // height: 252px;
  // width: 462px;
  height: 170px;
  width: 440px;
  border-width: 1.6px 1.6px 10px 1.6px;

  border-style: solid;

  margin-right: 20px;
  margin-left: 10px;
  // margin-bottom: 20px;
  margin-top: 10px;
  padding: 16px 16px 16px 35px;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  &:hover {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 24, 0.5);
    transform: scale(1.0014);
  }

  // > div {
  //   margin-bottom: 15px;
  // }
  .product-card-name {
    font-weight: 700;
    font-size: 16px;
    color: #005abc;
    height: 40px;
    > img {
      height: 40px;
    }
  }
  .product-card-description {
    width: 60%;
    font-weight: 600;
    font-size: 13px;
    max-height: 63px;
    height: 63px;
    margin-left: 44px;
  }
  .product-card-learn-more {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 28.7px;
    .learn-more-box-button {
      font-size: 13px !important;
    }
    // a {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   span:first-child {
    //     > svg {
    //       height: 16px;
    //       color: #005abc;
    //     }
    //   }
    //   span:nth-child(2) {
    //     color: #005abc;
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 19px;
    //     letter-spacing: 0px;
    //     text-align: left;
    //   }
    // }
    .product-card-get-access {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.isUnAvailable {
    // pointer-events: none;
    cursor: not-allowed;

    // .product-card-name {
    //   color: #8e8e8e;
    // }

    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    &:hover {
      .product-card-name,
      .product-card-description {
        filter: grayscale(100%);
      }

      border-color: #aeaeae;
      border-bottom-color: #aeaeae !important;
      box-shadow: none;
    }
  }
}
