.trail-sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  .sign-up-container-form {
    height: calc(100% - 100px);
    .sign-up-container-form-inner {
      padding-bottom: 3%;
      height: 90%;
      .form-text-box {
        width: 100%;
        height: 34px;
      }
      .pt-12 {
        padding-top: 12px;
      }
    }
  }
  h3 {
    margin: 10px 0 10px 0 !important;
    line-height: 46px !important;
  }
  .ant-form-item {
    margin-bottom: 5px !important;
  }
  .ant-form-item-label {
    padding: 0px !important;
  }
  &.success {
    justify-content: center;

    width: 80%;
    text-align: center;
    margin: auto;
  }
}

.link-color {
  color: #1677ff !important;
}

.w-100 {
  width: 100%;
}
.font-13 {
  font-size: 13px;
}
.font-12 {
  font-size: 12px;
}
.font-11 {
  font-size: 11px;
}
.justify-between {
  justify-content: space-between;
}

.error-msg-color {
  color: #ff3b30;
}

.btn-radius-4 {
  border-radius: 4px;
}
