.custom-reports-container {
  height: 100%;
  width: 100%;
  padding: 23px 45px 54px 84px;

  .custom-reports-container-top {
    height: 70px;
    width: 100%;
    margin-bottom: 5px;
    .products-list-container-header {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .custom-reports-container-bottom {
    height: calc(100% - 70px);
    .custom-report {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
.custom-report-description {
  width: 100%;
  height: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
}
