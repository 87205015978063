body {
  .ant-spin-nested-loading > div > .ant-spin.global-loading-spin {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
  .main-container {
    width: 100vw !important;
    height: 100vh !important;
    .page-container {
      width: 100vw !important;
      height: 100vh !important;
      background-color: #f2f2f2;
    }
  }
}
.default-button-class {
  font-weight: 600;
  color: #00244b;
  border: 1px solid #00244b;
  border-radius: 4px;
}
.main-container-react-loader {
  height: 100% !important;
  width: 100%;
  max-height: 100% !important;
}
.link-button-bold {
  font-weight: 700;
}
