.confirm-password-container {
  height: 100%;
  width: 294px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  > div {
    margin-bottom: 60px;
  }
}
