@mixin menuItem {
  font-size: 12px;
  font-weight: 400;
  padding: 2px;
  border-radius: 2px;
  margin-top: 8px;
  transition: all 0.3s;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: var(--ae-primary-hover);
  }
}
.ae-navbar-profile-sub-menu.ant-collapse {
  background-color: var(--ae-white);
  margin-top: 8px;
  width: Min(100%, 88px);
  &,
  .ant-collapse-content {
    border: none;
    border-radius: 2px !important;
  }
  & > .ant-collapse-item {
    border: none;
    & > .ant-collapse-header {
      border-radius: 2px !important;
      padding: 2px 8px 2px 4px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      background-color: var(--ae-primary-hover);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;

      & > div:first-child {
        display: flex;
        align-items: center;

        & > .ant-collapse-arrow {
          transition: all 0.3s;
          transform: rotate(0deg);
          margin-right: 6px;
        }
      }
    }
    &.ant-collapse-item-active {
      & > .ant-collapse-header > div > .ant-collapse-arrow {
        transform: rotate(90deg);
      }
    }
  }
  .ant-collapse-content {
    background-color: var(--ae-white);
    border-top: none;
    & > .ant-collapse-content-box {
      padding: 8px 0px;
      .ae-navbar-profile-sub-menu-items {
        padding: 8px 8px 12px 12px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;

        .ae-navbar-profile-sub-menu-item {
          @include menuItem();
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.ae-navbar-profile {
  width: 32px;
  height: 32px;
  .ae-navbar-profile-avatar.ant-avatar {
    font-style: normal;
    font-weight: 600;
    font-size: 13px !important;
    line-height: 18px !important;
    color: var(--ae-primary);
    border: 1px solid;
    border-color: var(--ae-primary);
    background-color: var(--ae-white);
  }
  .ae-navbar-profile-menu {
    padding: 8px 8px 12px 12px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: var(--ae-white);
    width: 108px;
    top: -7px;
    right: -10px;

    .ae-navbar-menu-avatar-container {
      top: 8px;
      right: 8px;
      .ae-navbar-menu-avatar {
        font-style: normal;
        font-weight: 600;
        font-size: 13px !important;
        line-height: 18px !important;
        color: var(--ae-primary);
        background-color: var(--ae-white);
      }
    }

    .ae-navbar-profile-menu-item {
      &:nth-child(2) {
        margin-top: 0;
        width: calc(100% - 40px);
      }
      @include menuItem();
    }
  }
}
