.products-list-container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  .products-list-inner-container {
    height: 100%;
    width: 100%;
    padding: 23px 45px 54px 84px;
    overflow-x: hidden;

    .products-list-container-header {
      height: auto;
      max-height: 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      .product-list-profile-options {
        > div {
          margin-left: 10px;
        }
      }
      .products-list-container-header-image {
        height: auto;
        > img {
          height: 60px;
        }
        > div {
          margin-top: 20px;
          font-size: 15px;
          > p {
            font-weight: 600;
          }
        }
      }
    }
    .products-list-container-text {
      font-size: 18px;
      font-weight: 700;
      margin-top: 45px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      > div:nth-child(2) {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .products-list-container-tenant-list-container {
      width: 100%;
      height: calc(100% - 180px);
      display: grid;
      grid-template-columns: min-content min-content;
      // grid-template-columns: repeat(auto-fill, 250px 250px);
      // grid-auto-columns: 200px;
      grid-template-rows: min-content min-content;
      margin-top: 20px;
      overflow-y: scroll;
      flex-wrap: wrap;
      .reports-empty {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 30px;
      }
    }
  }
}
